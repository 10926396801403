import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import CalendarIcon from "../../Assets/Icons/CalendarIcon";
import DeleteIcon from "../../Assets/Icons/DeleteIcon";
import EditIcon from "../../Assets/Icons/EditIcon";
import { useDeletedAllPosts, useDeletedPost } from "../../queries/mutation";
import { rootPaths } from "../../Routers/rootPaths";
import style from "./News.module.scss";

type Props = {
  activeDisplay: boolean;
  title: string;
  date: string;
  idNumber: number;
  opacity: number;
};

export default function PostCard(props: Props) {
  const location = useLocation();
  const navigation = useNavigate();
  const deletedPosts = useDeletedPost();
  const deletedAllPosts = useDeletedAllPosts();
  // const [postState, setPostState] = useRecoilState(postIdState);
  const deletePost = () => {
    deletedPosts.mutate(props.idNumber);
    deletedAllPosts.mutate(1);
  };
  const editPost = () => {
    navigation(location.pathname + "/edit_post/" + props.idNumber);
  };
  const menu = (
    <Menu
      items={[
        {
          key: 1,
          label: (
            <p style={{ margin: 0 }}>
              <EditIcon /> Tahrirlash
            </p>
          ),
          onClick: editPost,
        },
        {
          key: 2,
          label: (
            <p onClick={deletePost} className="deletePost">
              <DeleteIcon /> O'chirish
            </p>
          ),
        },
      ]}
    />
  );
  return (
    <div
      style={{
        display: props.activeDisplay ? "none" : undefined,
        opacity: props.opacity === 1 ? 1 : 0,
      }}
      className={style.postCard}
    >
      <div>
        <h3>{props.title}</h3>
        <p>
          <CalendarIcon /> {props.date}
        </p>
      </div>
      <Dropdown overlay={menu} placement="bottomLeft" arrow>
        <Button className="postDropdownEdit">
          <MoreOutlined width={20} height={20} />
        </Button>
      </Dropdown>
    </div>
  );
}
