import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./buildings.module.scss";
import ArrowRightIcon from "../../Assets/Icons/ArrowRightIcon";
import BuildingIcon from "../../Assets/Icons/BuildingIcon";
import { rootPaths } from "../../Routers/rootPaths";
import { DeveloperPaths } from "../../Routers/developer/developerPaths";

interface BuildingsProps {
  name: string;
  path: string;
}

export default function BuildingsCard({ name, path }: BuildingsProps) {
  return (
    <div className={styles.buildingsCard}>
      <div className={styles.buildingsCard_check}>
        <div className={styles.buildingsCard_check_icon}>
          <BuildingIcon />
        </div>
      </div>
      <h3>{name}</h3>
      <Link
        to={
          rootPaths.DEVELOPER + DeveloperPaths.INDEX + "/" + path + "/" + name
        }
      >
        <Button type="primary">
          Tahrirlash <ArrowRightIcon />
        </Button>
      </Link>
    </div>
  );
}
