export const popoverPositon = (
  e: React.MouseEvent<SVGPolygonElement, MouseEvent>,
  setOpenPopOver: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
      visible: boolean;
    }>
  >
) => {
  setOpenPopOver({
    visible: true,
    x: e.clientX,
    y: e.clientY,
  });
};
