import { Button, Modal, Spin } from "antd";
import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import CloseIcon from "../../Assets/Icons/CloseIcon";
import NoteIcon from "../../Assets/Icons/NoteIcon";
import TickCircleIcon from "../../Assets/Icons/TickCircleIcon";
import {
  buildingBlockFloorModal,
  buildingFloorFlatModal,
  flatInfoModal,
} from "../../atoms";
import { useBlockFatData } from "../../queries/queries";

const BuildingBlockFloorFlatModal = () => {
  const [floorflatsModal, setFloorflatsModal] = useRecoilState(
    buildingFloorFlatModal
  );
  const { data, isLoading, isFetching } = useBlockFatData(
    floorflatsModal.blockId,
    floorflatsModal.id
  );
  const setSvgBuildingFloorModal = useSetRecoilState(buildingBlockFloorModal);
  const setFlatInfo = useSetRecoilState(flatInfoModal);

  const handleClose = () => {
    setFloorflatsModal({ visible: false, id: null, name: "", blockId: 0 });
  };

  const handleSVG = (e: any, id: number) => {
    e.stopPropagation();
    setSvgBuildingFloorModal({
      visible: true,
      floor: id,
      name: "Xonadon",
      type: "flat",
      buildingId: floorflatsModal.blockId,
    });
  };

  const handleFlatInfo = (e: any, flatId: number) => {
    e.stopPropagation();
    setFlatInfo({
      visible: true,
      id: flatId,
      floorId: floorflatsModal.id,
      blockId: floorflatsModal.blockId,
    });
  };

  return (
    <Modal
      title={
        <>
          {floorflatsModal.name}{" "}
          <span onClick={handleClose}>
            <CloseIcon />
          </span>
        </>
      }
      zIndex={0}
      visible={floorflatsModal.visible}
      footer={false}
      closable={false}
      wrapClassName="building_block_svg_modal"
      width={1050}
      onCancel={handleClose}
    >
      <Spin spinning={isFetching || isLoading}>
        <div className="building_block_svg_modal_flats">
          {data
            ?.sort(function (a, b) {
              return a.flatId - b.flatId;
            })
            .map((flat) => (
              <div
                key={flat.flatId}
                className="building_block_svg_modal_flats_item"
              >
                <h1>
                  № {flat.flatNumber} -&gt; {flat.flatRooms} xonali{" "}
                  <span>
                    [{flat.flatArea} m<sup>2</sup>]
                  </span>
                </h1>
                <div>
                  <span onClick={(e: any) => handleFlatInfo(e, flat.flatId)}>
                    <NoteIcon />
                  </span>
                  <span onClick={(e: any) => handleSVG(e, flat.flatId)}>
                    SVG
                  </span>
                  <TickCircleIcon
                    color={
                      flat &&
                      (flat?.existFlatSVGImage && flat?.existFlatAdditionalInfo
                        ? "#04C45C"
                        : flat?.existFlatSVGImage ||
                          flat?.existFlatAdditionalInfo
                        ? "#FFC908"
                        : "")
                    }
                  />
                </div>
              </div>
            ))}
        </div>
      </Spin>
      <div className="building_block_svg_modal_footer">
        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </Modal>
  );
};

export default BuildingBlockFloorFlatModal;
