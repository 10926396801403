import axios, { AxiosRequestConfig } from "axios";
import { TOKEN_KEY } from "../utils/constants/localStorageKeys";
import { LocalStorage } from "./LocalStorage";

// for debugging
export const BASE_URL = "https://service.ahouse.softex.uz";

const addToken = (config: AxiosRequestConfig) => {
  const token = LocalStorage.get<string>(TOKEN_KEY);
  if (token) {
    config.headers!["X-Auth"] = `${token}`;
  }
  return config;
};

export const $api = axios.create({
  baseURL: BASE_URL,
});

$api.interceptors.request.use(addToken);
