import { Spin } from "antd";
import React from "react";
import { useWebsiteGetAll } from "../../queries/queries";
import styles from "./buildings.module.scss";
import BuildingsCard from "./BuildingsCard";

export default function Buildings() {
  const { data, isLoading, isFetching } = useWebsiteGetAll();

  return (
    <>
      <Spin spinning={isLoading || isFetching}>
        <div className={styles.buildings}>
          {data?.data.map((building) => (
            <BuildingsCard
              key={building.id}
              name={building.name}
              path={String(building.id)}
            />
          ))}
        </div>
      </Spin>
    </>
  );
}
