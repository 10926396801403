export const svgCenter = (points: string[]) => {
  let xArr =
    points &&
    points
      .map((item: any) => item.split(","))
      .map((arr: any) => Number(arr[0]))
      .filter((i: any) => !isNaN(i) && i !== 0);
  let yArr =
    points &&
    points
      .map((item: any) => item.split(","))
      .map((arr: any) => Number(arr[1]))
      .filter((i: any) => !isNaN(i) && i !== 0);

  let x = points && Math.min(...xArr) + 24;
  let y =
    points && Math.max(...yArr) - (Math.max(...yArr) - Math.min(...yArr)) / 2;

  return {
    x,
    y,
  };
};
