import React from "react";

const TrashIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8359 3.08081C10.6796 2.8632 8.50346 2.75439 6.33725 2.75439C5.05137 2.75439 3.76549 2.82363 2.4895 2.95222L1.16406 3.08081"
        stroke="#494949"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.73486 2.42882L4.87334 1.57816C4.97226 0.964891 5.05138 0.5 6.14932 0.5H7.85064C8.94858 0.5 9.02771 0.984674 9.12662 1.57816L9.2651 2.41893"
        stroke="#494949"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4412 3.16016L11.0158 9.68846C10.9466 10.7073 10.8873 11.4986 9.07714 11.4986H4.91287C3.10274 11.4986 3.04339 10.7073 2.97415 9.68846L2.54883 3.16016"
        stroke="#494949"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashIcon;
