import { message } from "antd";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { postIdState, webSiteRole } from "../atoms";
import { rootPaths } from "../Routers/rootPaths";
import { LocalStorage } from "../Service/LocalStorage";
import { $api } from "../Service/RequestService";
import {
  buildingImageEndpoints,
  builingFloorSvgImageEndpoints,
  builingSvgImageEndpoints,
  dashboardEndpoints,
  flatAdditionalInfoPartEndpoints,
  flatSVGImageEndpoints,
  floorImageEndpoints,
  houseImageEndpoints,
  newsEndpoints,
  websiteEndpoints,
} from "../utils/constants/apis";
import { CreatePostData } from "../utils/constants/CreatePostData";
import { TOKEN_KEY, WEBSITE_ROLES } from "../utils/constants/localStorageKeys";
import { queryNames } from "../utils/constants/queryNames";
import { BlockFlatData } from "../utils/models/BlockFlatData";
import { BuildingFloorSVGCreate } from "../utils/models/BuildingFloorSVGCreate";
import { BuildingImageCreate } from "../utils/models/BuildingImageCreate";
import { BuildingImageGetBy } from "../utils/models/BuildingImageGetBy";
import { BuildingSVGCreate } from "../utils/models/BuildingSVGCreate";
import { FileObject } from "../utils/models/File";
import { FlatAdditionalPartEdit } from "../utils/models/flatAddtionalPartEdit";
import { FlatSVGCreate } from "../utils/models/FlatSVGCreate";
import { FlatSVGGetBy } from "../utils/models/FlatSVGGetBy";
import { FloorImageCreate } from "../utils/models/FloorImageCreate";
import { FloorImageGetBy } from "../utils/models/FloorImageGetBy";
import { HouseImageCreate } from "../utils/models/HouseImageCreate";
import { Login } from "../utils/models/Login";
import { User } from "../utils/models/User";
import { dataMessages } from "../utils/paths/dataMessages";
import { rolPath } from "../utils/paths/rolPath";
import { baseErrorHandler } from "./baseErrorHandler";
import { showMessage } from "./showMessage";

export function useSignIn() {
  const historyPush = useNavigate();
  const setRoles = useSetRecoilState<string>(webSiteRole);
  return useMutation(
    async (data: Login) => {
      const res = await $api.post("/v1/login", data);
      if (!res.data.accept) return Promise.reject(res.data.message);
      return res.data.data as User;
    },
    {
      onSuccess: (data: any | User) => {
        LocalStorage.set(WEBSITE_ROLES, data.websiteRoles[0]);
        LocalStorage.set(TOKEN_KEY, data.accessToken);
        message.success(dataMessages.SUCCES);
        setRoles(data.websiteRoles[0]);
        if (data.websiteRoles[0] === rolPath.WEBSITE) {
          historyPush(rootPaths.MAIN + rootPaths.NEWS);
        }
      },
      onError: (err) => {
        baseErrorHandler(err);
      },
    }
  );
}

export function useUploadFile(getFile?: (data: FileObject[]) => void) {
  return useMutation(
    async (data: FormData) => {
      const res = await $api.post(websiteEndpoints.UPLOAD_FILE, data);
      return res.data;
    },
    {
      onSuccess: (data) => {
        if (getFile) getFile(data);
        showMessage("Yuklandi", true);
      },
      onError: (err) => {
        baseErrorHandler(err);
      },
    }
  );
}

export function useBuildingSvgImageCreate() {
  return useMutation(
    async (data: BuildingSVGCreate) => {
      const res = await $api.post(
        builingSvgImageEndpoints.BUILDING_SVG_IMAG_CREATEE,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        showMessage(data?.message?.uz, true);
      },
      onError: (err) => {
        baseErrorHandler(err);
      },
    }
  );
}

export function useBuildingFloorSvgImageCreate() {
  return useMutation(
    async (data: BuildingFloorSVGCreate) => {
      const res = await $api.post(
        builingFloorSvgImageEndpoints.BUILDING_FLOOR_SVG_IMAG_CREATEE,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        showMessage(data?.message?.uz, true);
      },
      onError: (err) => {
        baseErrorHandler(err);
      },
    }
  );
}

export function useHouseImageCreate(id: number) {
  const qc = useQueryClient();
  return useMutation(
    async (data: HouseImageCreate) => {
      const res = await $api.post(houseImageEndpoints.HOUSE_IMAGE_CREATE, data);
      return res.data;
    },
    {
      onSuccess: (data) => {
        showMessage(data?.message?.uz, true);
        qc.invalidateQueries([queryNames.BLOCK_STATISTIC, id]);
      },
      onError: (err) => {
        baseErrorHandler(err);
      },
    }
  );
}

export function useFloorImageCreate(buildingId: number) {
  const qc = useQueryClient();
  return useMutation(
    async (data: FloorImageCreate) => {
      const res = await $api.post(floorImageEndpoints.FLOOR_IMAGE_CREATE, data);
      return res.data;
    },
    {
      onSuccess: (data) => {
        qc.invalidateQueries([queryNames.FLOOR_IMAGE_GET_BY, buildingId]);
        showMessage(data?.message?.uz, true);
      },
      onError: (err) => {
        baseErrorHandler(err);
      },
    }
  );
}

export function useFloorImageDelete(buildingId: number) {
  const qc = useQueryClient();
  return useMutation(
    async (data: number) => {
      const res = await $api.post(
        floorImageEndpoints.FLOOR_IMAGE_DELETE + "/" + data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        qc.invalidateQueries([queryNames.FLOOR_IMAGE_GET_BY, buildingId]);
        showMessage(data?.message?.uz, true);
      },
      onError: (err) => {
        baseErrorHandler(err);
      },
    }
  );
}

export function useBuildingImageCreate(buildingId: number) {
  const qc = useQueryClient();
  return useMutation(
    async (data: BuildingImageCreate) => {
      const res = await $api.post(
        buildingImageEndpoints.BUILDING_IMAGE_CREATE,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        showMessage(data?.message?.uz, true);
        qc.invalidateQueries([queryNames.BUILDING_IMAGE_GET_BY, buildingId]);
      },
      onError: (err) => {
        baseErrorHandler(err);
      },
    }
  );
}

export function useBuildingImageDelete(buildingId: number) {
  const qc = useQueryClient();
  return useMutation(
    async (data: number) => {
      const res = await $api.delete(
        buildingImageEndpoints.BUILDING_IMAGE_DELETE + "/" + data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        showMessage(data?.message?.uz, true);
        qc.invalidateQueries([queryNames.BUILDING_IMAGE_GET_BY, buildingId]);
      },
      onError: (err) => {
        baseErrorHandler(err);
      },
    }
  );
}

export function useFlatSVGCreate(blockId: number, floorId: number) {
  const qc = useQueryClient();
  return useMutation(
    async (data: FlatSVGCreate) => {
      const res = await $api.post(flatSVGImageEndpoints.FLAT_SVG_CREATE, data);
      return res.data;
    },
    {
      onSuccess: (data) => {
        qc.invalidateQueries([queryNames.BLOCK_FLAT_DATA, blockId, floorId]);
        showMessage(data?.message?.uz, true);
      },
      onError: (err) => {
        baseErrorHandler(err);
      },
    }
  );
}

export function useFlatAdditionalPartEdit(
  blockId: number,
  floorId: number | null,
  flatId: number | null
) {
  const qc = useQueryClient();
  return useMutation(
    async (data: FlatAdditionalPartEdit) => {
      const res = await $api.put(
        flatAdditionalInfoPartEndpoints.FLAT_ADDITIONAL_INFO_PART_EDIT,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        qc.invalidateQueries([queryNames.BLOCK_FLAT_DATA, blockId, floorId]);
        qc.invalidateQueries([
          queryNames.FLAT_ADDITIONAL_INFO_PART_ALL,
          flatId,
        ]);
        showMessage(data?.message?.uz, true);
      },
      onError: (err) => {
        baseErrorHandler(err);
      },
    }
  );
}

export function useFlatAdditionalPartDelete(
  blockId: number,
  floorId: number | null,
  flatId: number | null
) {
  const qc = useQueryClient();
  return useMutation(
    async (data: { id: number }) => {
      const res = await $api.delete(
        flatAdditionalInfoPartEndpoints.FLAT_ADDITIONAL_INFO_PART_DELETE +
          "/" +
          data.id
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        qc.invalidateQueries([queryNames.BLOCK_FLAT_DATA, blockId, floorId]);
        qc.invalidateQueries([
          queryNames.FLAT_ADDITIONAL_INFO_PART_ALL,
          flatId,
        ]);
        showMessage(data?.message?.uz, true);
      },
      onError: (err) => {
        baseErrorHandler(err);
      },
    }
  );
}

export function useCreatePost() {
  return useMutation(
    async (data: CreatePostData) => {
      const res = await $api.post("/v1/news/create", data);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success(dataMessages.ADD);
      },
    }
  );
}

export function useDeletedPost() {
  const queryClient = useQueryClient();
  return useMutation(
    async (newsId: number) => {
      const res = await $api.delete(`/v1/news/delete/${newsId}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.info(dataMessages.DELETED);
        queryClient.invalidateQueries([queryNames.ALL_POSTS]);
      },
    }
  );
}

export function useSearchPost() {
  return useMutation(
    async (newsId: any) => {
      const res = await $api.get(
        `/v1/news/all-news?page=1&size=10&deleted=false&search=${newsId}`
      );
      return res.data;
    },
    {
      onSuccess: (data: any) => {
        console.log(data);
      },
      onError: () => {
        // message.error(dataMessages.ERRORPOST);
      },
    }
  );
}

export function useDeletedAllPosts() {
  return useMutation(async (page: number) => {
    const res = await $api.get(
      `/v1/news/all-news?page=${page}&size=10&deleted=true`
    );
    return res.data;
  });
}

export function useAllSecondPost() {
  return useMutation(async (page: number) => {
    const res = await $api.get(
      `/v1/news/all-news?page=${page}&size=100&deleted=false`
    );
    return res.data;
  });
}

export function useEditPost() {
  const setPostIdData = useSetRecoilState(postIdState);
  return useMutation(
    async (newsId: number) => {
      const res = await $api.get(`/v1/news/news-by-id/${newsId}`);
      return res.data;
    },
    {
      onSuccess: (data: any) => {
        console.log(data);
        setPostIdData({
          title: data?.data?.title,
          body: data?.data?.body,
          language: data?.data?.language,
          tags: data?.data?.tags,
          newsId: data?.data?.id,
        });
      },
    }
  );
}

export function useEditPostData() {
  return useMutation(
    async (data: CreatePostData) => {
      const res = await $api.put(
        newsEndpoints.NEWS_EDIT + `/${data.newsId}`,
        data
      );
      return res.data;
    },
    {
      onSuccess: () => {
        message.success(dataMessages.SAVE);
      },
      onError: () => {
        message.error(dataMessages.ERROR);
      },
    }
  );
}

export function useSendBlockSVG() {
  return useMutation<BuildingSVGCreate[], AxiosError, number>(async (id) => {
    const res = await $api.get(
      builingSvgImageEndpoints.BUILDING_SVG_IMAGE_GET_All + "/" + id
    );
    return res.data.data;
  });
}

export function useSendBlockFloorSVG() {
  return useMutation<
    BuildingFloorSVGCreate[],
    AxiosError,
    { blockId: number; id: number }
  >(async (data) => {
    const res = await $api.get(
      builingFloorSvgImageEndpoints.BUILDING_FLOOR_SVG_IMAGE_GET_All +
        `/${data.blockId}?floor=${data.id}`
    );
    return res.data.data;
  });
}

export function useSendBlockFloorFlats() {
  return useMutation<
    BlockFlatData[],
    AxiosError,
    { blockId: number; id: number }
  >(async (data) => {
    const res = await $api.get(
      dashboardEndpoints.BLOCK_FLAT_DATA + `/${data.blockId}?floor=${data.id}`
    );
    return res.data.data;
  });
}

export function useSendBlockFloorFlatsSVG() {
  return useMutation<FlatSVGGetBy, AxiosError, number>(async (flatId) => {
    const res = await $api.get(
      flatSVGImageEndpoints.FLAT_SVG_GET_BY + `/${flatId}`
    );
    return res.data.data;
  });
}

export function useSendBlockImage() {
  return useMutation<FloorImageGetBy, AxiosError, number>(async (id) => {
    const res = await $api.get(
      floorImageEndpoints.FLOOR_IMAGE_GET_BY + `/${id}`
    );
    return res.data.data;
  });
}

export function useSendBlockFloorImage() {
  return useMutation<
    BuildingImageGetBy[],
    AxiosError,
    { blockId: number; id: number }
  >(async (data) => {
    const res = await $api.get(
      buildingImageEndpoints.BUILDING_IMAGE_GET_BY +
        `/${data.blockId}?floor=${data.id}`
    );
    return res.data.data;
  });
}
