import { SetterOrUpdater } from "recoil";

interface IProps {
  editor: {
    visible: boolean;
    image: string;
    naturalWidth: number;
    naturalHeight: number;
    size: number;
    color: string;
    opacity: number;
  };
  setEditor: SetterOrUpdater<{
    visible: boolean;
    image: string;
    naturalWidth: number;
    naturalHeight: number;
    size: number;
    color: string;
    opacity: number;
  }>;
}

export const windowWheel = (e: WheelEvent, props: IProps) => {
  if (e.ctrlKey) {
    e.stopPropagation();
    e.preventDefault();
    if (e.deltaY === 100) {
      props.setEditor({
        ...props.editor,
        size: props.editor.size - 0.1,
      });
    } else if (props.editor.size <= 4) {
      props.setEditor({
        ...props.editor,
        size: props.editor.size + 0.1,
      });
    }
  }
};
