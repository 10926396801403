import React from "react";
import { Route, Routes } from "react-router-dom";
import { rootRoutes } from "./rootRoutes";

export default function RootRouter() {
  return (
    <Routes>
      {rootRoutes.map((route) => (
        <Route
          element={<route.component />}
          path={route.exact ? route.path : `${route.path}/*`}
          key={route.path}
        />
      ))}
    </Routes>
  );
}
