import { Button, Input, Pagination, Spin } from "antd";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { webSiteRole } from "../../atoms";
import { useDeletedAllPosts, useSearchPost } from "../../queries/mutation";
import { useAllDeletePosts, useAllPosts } from "../../queries/queries";
import { DeveloperPaths } from "../../Routers/developer/developerPaths";
import { rootPaths } from "../../Routers/rootPaths";
import DeletePostCard from "./DeletePostCard";
import style from "./News.module.scss";
import PostCard from "./PostCard";
import { LoadingOutlined } from "@ant-design/icons";
import { useQueryClient } from "react-query";
import { queryNames } from "../../utils/constants/queryNames";

export default function Posts() {
  const push = useNavigate();
  const refSearch = useRef<any>();
  const searchPostData = useSearchPost();
  const deletedPostNumber = useAllDeletePosts();
  const siteRole = useRecoilValue<string>(webSiteRole);
  const [pageActive, setPageActive] = useState(false);
  const [postIdNumber, setPostIdNumber] = useState();
  // const [deletedPost, setDeletedPost] = useState<string>("false");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageNumberAll, setPageNumberAll] = useState<number>(1);
  const [display, setDisplay] = useState<boolean>(true);
  const [activeDisplay, setActiveDisplay] = useState<boolean>(false);
  const allPost = useAllPosts(pageNumberAll);
  const allDeletedPost = useDeletedAllPosts();
  const queryClient = useQueryClient();
  const changePage = (changePageNumber: number) => {
    // secondAllPost.mutate(pageNumber);
    setPageNumber(changePageNumber);
    allDeletedPost.mutate(changePageNumber);
  };
  const changePageAll = (changeNumber: number) => {
    queryClient.invalidateQueries([queryNames.ALL_POSTS]);
    setPageNumberAll(changeNumber);
    console.log(changeNumber);
  };
  const createPost = () => {
    push(
      (siteRole ? rootPaths.MAIN : rootPaths.DEVELOPER) +
        rootPaths.NEWS +
        DeveloperPaths.CREATEPOST
    );
  };
  const activePost = () => {
    setDisplay(true);
    setActiveDisplay(false);
    setPageActive(false);
  };
  const deleteCards = () => {
    setDisplay(false);
    setActiveDisplay(true);
    // setDeletedPost("true");
    allDeletedPost.mutate(pageNumber);
    setPageActive(true);
  };
  const focusSearch = () => {
    refSearch.current.input.className = "ant-input";
    refSearch.current.focus();
  };
  const searchPosts = (postId: any) => {
    searchPostData.mutate(postId.target.value);
    setPostIdNumber(postId.target.value);
  };
  let totalAllPost = allPost?.data?.data?.totalElements;
  let totalDeletePost = allPost?.data?.data?.totalElements;
  let deletePostNumber = deletedPostNumber?.data?.data?.totalElements;
  return (
    <div className={style.post}>
      <div className={style.post_header}>
        <div>
          <p
            onClick={activePost}
            className={`${style.post_header_btn} ${
              activeDisplay ? undefined : style.post_header_btn_active
            }`}
          >
            Chop etilganlar (<span>{allPost?.data?.data?.totalElements}</span>)
          </p>
          <p
            onClick={deleteCards}
            className={`${style.post_header_btn} ${
              display ? undefined : style.post_header_btn_active
            }`}
          >
            O’chirilganlar (<span>{deletePostNumber}</span>)
          </p>
        </div>
        <div>
          <Input
            ref={refSearch}
            className={display ? "searchPostInput" : "d-none"}
            onChange={searchPosts}
            placeholder="Qidiruv"
          />
          <Button
            style={{ display: pageActive ? "none" : undefined }}
            onClick={focusSearch}
            className="searchPost"
          >
            <SearchIcon />
          </Button>
          <Button
            onClick={createPost}
            className={style.post_header_createBtn}
            type="primary"
          >
            Yangi yaratish
          </Button>
        </div>
      </div>
      {pageNumber > 1
        ? undefined
        : allPost?.data?.data?.data?.map(
            (item: { id: number; title: string; date: string }) =>
              postIdNumber ? undefined : (
                <Spin
                  spinning={allPost.isLoading}
                  indicator={<LoadingOutlined />}
                >
                  <PostCard
                    key={item.id}
                    idNumber={item.id}
                    date={item.date}
                    title={item.title}
                    activeDisplay={activeDisplay}
                    opacity={1}
                  />
                </Spin>
              )
          )}
      {postIdNumber
        ? searchPostData?.data?.data?.data?.map(
            (search: { id: number; title: string; date: string }) => (
              <Spin
                spinning={searchPostData.isLoading}
                indicator={<LoadingOutlined />}
              >
                <PostCard
                  key={search.id}
                  idNumber={search.id}
                  date={search.date}
                  title={search.title}
                  activeDisplay={activeDisplay}
                  opacity={searchPostData?.data?.data ? 1 : 0}
                />
              </Spin>
            )
          )
        : undefined}
      {allDeletedPost?.data?.data?.data?.map(
        (data: { id: number; title: string; date: string }) => (
          <Spin
            spinning={allDeletedPost.isLoading}
            indicator={<LoadingOutlined />}
          >
            <DeletePostCard
              key={data.id}
              date={data.date}
              title={data.title}
              display={display}
            />
          </Spin>
        )
      )}
      <div
        className={style.post_pagination}
        style={{ display: pageActive ? undefined : "none" }}
      >
        <Pagination
          onChange={changePage}
          className="postPagination"
          total={totalDeletePost}
          pageSize={10}
          current={pageNumber}
        />
      </div>
      <div
        className={style.post_pagination}
        style={{ display: pageActive ? "none" : undefined }}
      >
        <Pagination
          onChange={changePageAll}
          className="postPagination"
          total={totalAllPost}
          pageSize={10}
          current={pageNumberAll}
        />
      </div>
    </div>
  );
}
