import React, { FC } from "react";

interface IProps {
  stroke?: string;
  size?: number;
}

const PathSquareIcon: FC<IProps> = ({ stroke = "#494949", size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7604 7.72005L12.2958 6.25546C11.7417 5.7013 10.9817 5.73296 10.6096 6.3188L9.79416 7.60921L12.3987 10.2138L13.6892 9.39838C14.2512 9.05005 14.2829 8.23463 13.7604 7.72005Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.79418 7.60916L7.90209 7.49833C6.84126 7.43499 6.47709 7.75957 6.35834 8.76499L5.88334 12.7787C5.78043 13.6258 6.39001 14.2275 7.23709 14.1325L11.2508 13.6575C12.2563 13.5387 12.6204 13.1746 12.5175 12.1137L12.4067 10.2217"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.4296 13.5784L7.89418 12.1138"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.62501 17.9166H12.375C16.3333 17.9166 17.9167 16.3333 17.9167 12.3749V7.62492C17.9167 3.66659 16.3333 2.08325 12.375 2.08325H7.62501C3.66668 2.08325 2.08334 3.66659 2.08334 7.62492V12.3749C2.08334 16.3333 3.66668 17.9166 7.62501 17.9166Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PathSquareIcon;
