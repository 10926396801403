import { SetterOrUpdater } from "recoil";
import { coordinateSVG } from "../../../utils/helper/coordinateSVG";
import { EditorType } from "../../../utils/models/editorType";

interface IProps {
  dragging: {
    index: number[] | null;
    visible: boolean;
  };
  grabbing: boolean;
  create: {
    index: number | null;
    visible: boolean;
  };
  setCreate: React.Dispatch<
    React.SetStateAction<{
      index: number | null;
      visible: boolean;
    }>
  >;
  setEditorPoints: SetterOrUpdater<EditorType[]>;
  editorPoints: EditorType[];
  setMovePosition: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    }>
  >;
}

export const handleSVGCreate = (e: any, props: IProps) => {
  e.preventDefault();
  e.stopPropagation();
  if (!props.dragging.visible) {
    const p = coordinateSVG(e, "SVGeditor");
    props.setMovePosition({
      x: p.x,
      y: p.y,
    });
    if (!props.grabbing) {
      if (
        props.create.visible ||
        (props.create.visible && props.create.index === null)
      ) {
        props.setCreate({
          index: props.editorPoints.length > 0 ? props.editorPoints.length : 0,
          visible: false,
        });
        props.setEditorPoints([
          ...props.editorPoints,
          { points: [p.x + "," + p.y] },
        ]);
      } else {
        props.setEditorPoints([
          ...props.editorPoints.map((item, index) => {
            return {
              ...item,
              points:
                index === props.create.index
                  ? [...item.points, p.x + "," + p.y]
                  : item.points,
            };
          }),
        ]);
      }
    }
  }
};
