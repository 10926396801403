export const dataMessages = {
  SUCCES: "Muvofaqqiyatli!",
  ERROR: "Xatolik mavjud",
  INFO: "Qaytadan urinib ko'ring",
  ADD: "Yaratildi",
  DELETED: "O'chirildi",
  ERRORPOST: "Bunday post yo'q",
  NOTDATA: "Ma'lumot kiritilmagan",
  SAVE: "Saqlandi",
};
