import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import style from "./News.module.scss";
import { useRecoilValue } from "recoil";
import { postIdState } from "../../atoms";
import { useEditPostData, useUploadFile } from "../../queries/mutation";
import { useNewsById } from "../../queries/queries";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import UploadIcon from "../../Assets/Icons/UploadIcon";
import { FileObject } from "../../utils/models/File";
import { BASE_URL } from "../../Service/RequestService";
import RefreshIcon from "../../Assets/Icons/RefreshIcon";
import TrushSqareIcon from "../../Assets/Icons/TrushSqareIcon";

const { Option } = Select;
const { TextArea } = Input;

export default function EditPost() {
  const [uploadImage, setUploadImage] = useState({ id: 0, imagePath: "" });
  const [form] = Form.useForm();
  const params = useParams();
  const uploadFile = useUploadFile(getFile);
  const { data } = useNewsById(Number(params?.id));
  const editPostData = useEditPostData();
  const postIdValue = useRecoilValue(postIdState);
  const [title, setTitle] = useState<string>(postIdValue.title);
  const [text, setText] = useState<string>(postIdValue.body);
  const [first, setfirst] = useState(postIdValue.tags);
  const [lang, setLang] = useState(postIdValue.language);
  const [tagName, setTagName] = useState<string[]>();

  function getFile(file: FileObject[]) {
    setUploadImage({
      id: file[0].id,
      imagePath: file[0].absolutePath,
    });
  }

  const handleDrop = (acceptedFiles: any) => {
    const formData = new FormData();
    formData.append("files", acceptedFiles[0]);
    uploadFile.mutate(formData);
  };

  useEffect(() => {
    if (data?.imageAbsolutePath) {
      setUploadImage({ id: 0, imagePath: data?.imageAbsolutePath });
    }
    form.setFieldsValue(data);
    setTagName(data?.tags.split(","));
  }, [data]);

  const onFinish = (fields: any) => {
    editPostData.mutate({
      body: fields?.body,
      imageId: uploadImage.id,
      language: fields?.lang,
      newsId: Number(params?.id),
      tags: tagName ? tagName?.join(",") : "",
      title: fields?.title,
    });
  };
  const changeTitle = (e: any) => {
    setTitle(e.target.value);
  };
  const changeText = (e: any) => {
    setText(e.target.value);
  };
  const changeTag = (e: any) => {
    setfirst(e.target.value);
  };
  const pressEnterTag = (e: any) => {
    e.preventDefault();
    setTagName((arg: any) => [...arg, first]);
    setfirst("");
  };
  const changeLang = (e: any) => {
    setLang(e);
  };
  return (
    <div className={style.createPost}>
      <h1>Yangiliklar</h1>
      <Form
        name="createPosts"
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        <Row>
          <Col span={12}>
            <div className={style.createPost_text}>
              <Form.Item name="title" noStyle>
                <Input
                  onChange={changeTitle}
                  value={title}
                  placeholder="Sarlavha"
                />
              </Form.Item>
              <Form.Item name="body" noStyle>
                <TextArea
                  value={text}
                  onChange={changeText}
                  placeholder="Post matni"
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div className="createPostForm">
              <Form.Item name="language" label="Chop etish tili">
                <Select
                  onChange={changeLang}
                  value={lang}
                  placeholder={"Til turini tanlang"}
                >
                  <Option value={"UZ"}>O'zbek tili</Option>
                  <Option value={"RU"}>Русскый язык</Option>
                </Select>
              </Form.Item>
              <Form.Item name="tag" label="Teglar" initialValue={first}>
                <Input
                  onPressEnter={pressEnterTag}
                  onChange={changeTag}
                  placeholder="Teglarni kiriting"
                  value={first}
                />
                <div className={style.createPost_tags}>
                  {tagName?.map((item: any, index: number) => (
                    <div
                      onClick={() =>
                        setTagName(
                          tagName.filter(
                            (i: any, index2: number) => index2 !== index
                          )
                        )
                      }
                      style={{ opacity: tagName ? 1 : 0 }}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </Form.Item>
              <Form.Item name="img" label="Rasm">
                <Spin spinning={uploadFile.isLoading}>
                  {data?.imageAbsolutePath ? (
                    <div className={style.news_upload_image_edit}>
                      <img
                        src={BASE_URL + uploadImage.imagePath}
                        alt=""
                        width={400}
                      />
                      <div>
                        <Button className="customMiniButton">
                          <label
                            htmlFor={"file" + data?.id}
                            style={{ width: "100%" }}
                          >
                            <RefreshIcon />
                          </label>
                          <input
                            type="file"
                            id={"file" + data?.id}
                            pattern="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleDrop(e.target.files);
                            }}
                          />
                        </Button>
                        <Button className="customMiniButton">
                          <TrushSqareIcon />
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <Dropzone
                      onDrop={handleDrop}
                      accept={{
                        "image/*": [".jpeg", ".png", ".PNG"],
                      }}
                      minSize={1024}
                      maxSize={30720000}
                      multiple={false}
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                      }) => {
                        const additionalClass = isDragAccept
                          ? style.news_upload_image_accept
                          : isDragReject
                          ? style.news_upload_image_reject
                          : "";

                        return (
                          <div
                            {...getRootProps({
                              className: `${style.news_upload_image} ${additionalClass}`,
                            })}
                          >
                            <input {...getInputProps()} />
                            <UploadIcon />
                            <h1>
                              Fayli oling va shuyerga tashlang yoki
                              <span>Tanlang</span>
                            </h1>
                            <p>Fayl formatlari: JPEG, JPG</p>
                          </div>
                        );
                      }}
                    </Dropzone>
                  )}
                </Spin>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Saqlash
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
