export const queryNames = {
  GET_ALL_HOUSE: "GET_ALL_HOUSE",
  BLOCK_STATISTIC: "BLOCK_STATISTIC",
  BLOCK_FLOOR_STATISTIC: "BLOCK_FLOOR_STATISTIC",
  BLOCK_FLAT_DATA: "BLOCK_FLAT_DATA",
  FILE: "FILE",
  BUILDING_SVG_IMAGE_GET_All: "BUILDING_SVG_IMAGE_GET_All",
  BUILDING_FLOOR_SVG_IMAGE_GET_All: "BUILDING_FLOOR_SVG_IMAGE_GET_All",
  FLAT_ADDITIONAL_INFO_ALL: "FLAT_ADDITIONAL_INFO_ALL",
  BUILDING_IMAGE_GET_BY: "BUILDING_IMAGE_GET_BY",
  FLOOR_IMAGE_GET_BY: "FLOOR_IMAGE_GET_BY",
  FLAT_SVG_GET_BY: "FLAT_SVG_GET_BY",
  FLAT_ADDITIONAL_INFO_PART_ALL: "FLAT_ADDITIONAL_INFO_PART_ALL",
  ALL_POSTS: "ALL_POSTS",
  ALL_DELETE_POST: "ALL_DELETE_POST",
  NEWS_BY_ID: "NEWS_BY_ID",
};
