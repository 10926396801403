import { Button, Form, Input, Modal, Space } from "antd";
import React, { FC, useEffect } from "react";
import CloseIcon from "../../Assets/Icons/CloseIcon";
import { useBuildingSvgImageCreate } from "../../queries/mutation";
import { useBuildingSvgImageGetBy } from "../../queries/queries";

interface BuildingBlockSVGModalProps {
  setSvgModalVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      name: string;
      blockId: number;
    }>
  >;
  visible: boolean;
  name: string;
  blockId: number;
}

const BuildingBlockSVGModal: FC<BuildingBlockSVGModalProps> = ({
  setSvgModalVisible,
  visible,
  name,
  blockId,
}) => {
  const { data: buildingSVGData } = useBuildingSvgImageGetBy(blockId);
  const [form] = Form.useForm();
  const buildingImageSvgMutation = useBuildingSvgImageCreate();
  const handleClose = () => {
    setSvgModalVisible({ visible: false, name: "", blockId: 0 });
    form.resetFields();
  };

  const onFinish = (values: {
    frontImage: string;
    backgroundImage: string;
  }) => {
    buildingImageSvgMutation.mutate({
      buildingId: blockId,
      frontImage: values.frontImage,
      backgroundImage: values.backgroundImage,
      ...(buildingSVGData &&
        buildingSVGData.length > 0 && { id: buildingSVGData[0].id }),
    });
  };

  useEffect(() => {
    if (buildingSVGData) {
      form.setFieldsValue({
        frontImage: buildingSVGData[0]?.frontImage,
        backgroundImage: buildingSVGData[0]?.backgroundImage,
      });
    }
    //eslint-disable-next-line
  }, [buildingSVGData]);

  return (
    <Modal
      title={
        <>
          {name}{" "}
          <span onClick={handleClose}>
            <CloseIcon />
          </span>
        </>
      }
      visible={visible}
      onCancel={handleClose}
      closable={false}
      footer={false}
      wrapClassName="building_block_svg_modal"
      width={800}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Space>
          <Form.Item label="SVG (old qismi)" name={"frontImage"}>
            <Input.TextArea
              className="customInput"
              autoSize={{ minRows: 10, maxRows: 10 }}
            />
          </Form.Item>
          <Form.Item label="SVG (orqa qismi)" name={"backgroundImage"}>
            <Input.TextArea
              className="customInput"
              autoSize={{ minRows: 10, maxRows: 10 }}
            />
          </Form.Item>
        </Space>
        <div className="building_block_svg_modal_footer">
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            htmlType="submit"
            loading={buildingImageSvgMutation.isLoading}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default BuildingBlockSVGModal;
