import BuildingId from "../../pages/BuildingId/BuildingId";
import Buildings from "../../pages/Buildings/Buildings";
import CreatePost from "../../pages/News/CreatePost";
import EditPost from "../../pages/News/EditPost";
import News from "../../pages/News/News";
import { LocalStorage } from "../../Service/LocalStorage";
import { USER_KEY } from "../../utils/constants/localStorageKeys";
import { User } from "../../utils/models/User";
import { MainPaths } from "../main/mainPaths";
import { rootPaths } from "../rootPaths";
import { DeveloperPaths } from "./developerPaths";

const user = LocalStorage.get<User>(USER_KEY);

const routes = [
  {
    path: DeveloperPaths.INDEX,
    component: Buildings,
    exact: true,
  },
  {
    path: DeveloperPaths.NEWS,
    component: News,
    exact: true,
  },
  {
    path: rootPaths.NEWS + DeveloperPaths.CREATEPOST,
    component: CreatePost,
    exact: true,
  },
  {
    path: rootPaths.NEWS + DeveloperPaths.EDIT,
    component: EditPost,
    exact: true,
  },
  {
    path: DeveloperPaths.BUILDING_ID,
    component: BuildingId,
    exact: true,
  },
  {
    path: DeveloperPaths.NEWS + MainPaths.EDIT,
    component: EditPost,
    exact: true,
  },
];

const developerRoutes: typeof routes = routes;
// const developerRoutes: typeof routes = []

// if (typeof user === 'object' && user) {
//   routes.forEach((route) => {
//     if (route.permission) {
//       if (user.role.permissions.includes(route.permission)) {
//         developerRoutes.push(route)
//       }
//     } else {
//       developerRoutes.push(route)
//     }
//   })
// }

export default developerRoutes;
