import React from "react";
import style from "./News.module.scss";
import CalendarIcon from "../../Assets/Icons/CalendarIcon";

type Props = {
  display: boolean;
  title: string;
  date: string;
};

export default function DeletePostCard(props: Props) {
  return (
    <div
      style={{ display: props.display ? "none" : undefined }}
      className={style.postCard}
    >
      <div>
        <h3>{props.title}</h3>
        <p>
          <CalendarIcon /> {props.date}
        </p>
      </div>
    </div>
  );
}
