import { Button, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import styles from "../../pages/BuildingId/buildingId.module.scss";
import Dropzone from "react-dropzone";
import { useRecoilState } from "recoil";
import CloseIcon from "../../Assets/Icons/CloseIcon";
import UploadIcon from "../../Assets/Icons/UploadIcon";
import { buildingFloorImportImageModal } from "../../atoms";
import { FileObject } from "../../utils/models/File";
import {
  useBuildingImageCreate,
  useBuildingImageDelete,
  useUploadFile,
} from "../../queries/mutation";
import { BASE_URL } from "../../Service/RequestService";
import { useBuildingImageGetBy } from "../../queries/queries";
import TrushSqareIcon from "../../Assets/Icons/TrushSqareIcon";
import RefreshIcon from "../../Assets/Icons/RefreshIcon";

const BuildingFloorUploadImageModal = () => {
  const [uploadImageModal, setUploadImageModal] = useRecoilState(
    buildingFloorImportImageModal
  );
  const { data, isLoading, isFetching } = useBuildingImageGetBy(
    uploadImageModal.buildingId,
    uploadImageModal.floor
  );
  const floorImageCreate = useBuildingImageCreate(uploadImageModal.buildingId);
  const floorImageDelete = useBuildingImageDelete(uploadImageModal.buildingId);
  const [absolutePath, setAbsolutePath] = useState("");

  const handleClose = () => {
    setUploadImageModal({
      visible: false,
      floor: 0,
      name: "",
      type: "",
      buildingId: 0,
    });
    setAbsolutePath("");
  };

  const handleDrop = (acceptedFiles: any) => {
    const formData = new FormData();
    formData.append("files", acceptedFiles[0]);
    uploadFile.mutate(formData);
  };

  const getFile = (file: FileObject[]) => {
    floorImageCreate.mutate({
      buildingId: uploadImageModal.buildingId,
      imageId: file[0].id,
      id: data && data?.length > 0 ? data[0]?.buildingImageId : 0,
      floor: uploadImageModal.floor,
    });
    setAbsolutePath(file[0].absolutePath);
    console.log(file);
  };
  const uploadFile = useUploadFile(getFile);

  useEffect(() => {
    if (data) {
      setAbsolutePath(data[0]?.imagePath);
    }
  }, [data]);

  const handleDelete = () => {
    if (data && data[0]) {
      floorImageDelete?.mutate(data[0]?.buildingImageId);
    }
  };

  return (
    <Modal
      title={
        <>
          {uploadImageModal.name}{" "}
          <span onClick={handleClose}>
            <CloseIcon />
          </span>
        </>
      }
      visible={uploadImageModal.visible}
      onCancel={handleClose}
      footer={false}
      closable={false}
      wrapClassName="building_block_svg_modal"
      width={650}
    >
      <Spin spinning={uploadFile.isLoading || isFetching || isLoading}>
        {data && data?.length > 0 ? (
          <div className="building_block_svg_modal_imageCustomUpload">
            <img
              src={BASE_URL + absolutePath}
              alt=""
              style={{ width: "100%" }}
            />
            <div>
              <Button className="customMiniButton">
                <label
                  htmlFor={
                    "file" +
                    uploadImageModal.buildingId +
                    uploadImageModal.floor
                  }
                  style={{ width: "100%" }}
                >
                  <RefreshIcon />
                </label>
                <input
                  type="file"
                  id={
                    "file" +
                    uploadImageModal.buildingId +
                    uploadImageModal.floor
                  }
                  pattern="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleDrop(e.target.files);
                  }}
                />
              </Button>
              <Button className="customMiniButton" onClick={handleDelete}>
                <TrushSqareIcon />
              </Button>
            </div>
          </div>
        ) : (
          <Dropzone
            onDrop={handleDrop}
            accept={{
              "image/*": [".jpeg", ".png", ".PNG"],
            }}
            minSize={1024}
            maxSize={30720000}
            multiple={false}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragAccept,
              isDragReject,
            }) => {
              const additionalClass = isDragAccept
                ? styles.buildingId_fullImageUpload_accept
                : isDragReject
                ? styles.buildingId_fullImageUpload_reject
                : "";

              return (
                <div
                  style={{
                    width: "100%",
                    marginBottom: 32,
                    background: "#ffffff",
                  }}
                  {...getRootProps({
                    className: `${styles.buildingId_fullImageUpload} ${additionalClass}`,
                  })}
                >
                  <input {...getInputProps()} />
                  <UploadIcon />
                  <h1>
                    Fayli oling va shuyerga tashlang yoki<span>Tanlang</span>
                  </h1>
                  <p>Fayl formatlari: JPEG, JPG</p>
                </div>
              );
            }}
          </Dropzone>
        )}
      </Spin>
      {/* <div className="building_block_svg_modal_imageProgress">
        <span>
          <DocumentTextIcon />
        </span>
        <div>
          <p>
            File_Name.zip <span>52%</span>
          </p>
          <Progress percent={50} status="active" showInfo={false} />
        </div>
        <b>
          <CloseIconBorderIcon />
        </b>
      </div> */}
      <div
        className="building_block_svg_modal_footer"
        style={{ justifyContent: "center" }}
      >
        <Button onClick={handleClose}>Cancel</Button>
        <Button>Save</Button>
      </div>
    </Modal>
  );
};

export default BuildingFloorUploadImageModal;
