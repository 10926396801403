import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { openEditor, openEditorPoints } from "../../../atoms";
import { additionalPoint } from "../../../utils/helper/additionalPoint";
import { getMeta } from "../../../utils/helper/getMeta";
import {
  grabbingScrollDown,
  grabbingScrollLeave,
  grabbingScrollMove,
  grabbingScrollUp,
} from "../../../utils/helper/grabbingScroll";
import {
  pointsDraggingDown,
  pointsDraggingMove,
  pointsDraggingUp,
} from "../../../utils/helper/pointsDragging";
import { popoverPositon } from "../../../utils/helper/popoverPostion";
import { stringPosition } from "../../../utils/helper/stringPosition";
import { svgCenter } from "../../../utils/helper/svgCenter";
import { handleSVGCreate } from "../helper/handleSVG";
import { windowKeyDown } from "../helper/windowKey";
import { windowWheel } from "../helper/windowWheel";
import OpenEditorPopover from "../open-editor-popover/OpenEditorPopover";
import styles from "./openEditorContent.module.scss";

const OpenEditorContent = () => {
  const [openPopOver, setOpenPopOver] = useState({
    x: 0,
    y: 0,
    visible: true,
  });
  const [deleteIndex, setDeleteIndex] = useState(0);
  const bodyRef = useRef<any>(null);
  const [create, setCreate] = useState<{
    index: number | null;
    visible: boolean;
  }>({
    index: null,
    visible: true,
  });
  const [grabbing, setGrabbing] = useState(false);
  const [dragging, setDragging] = useState<{
    index: number[] | null;
    visible: boolean;
  }>({
    index: null,
    visible: false,
  });
  const [position, setPosition] = useState({
    startY: 0,
    startX: 0,
    scrollLeft: 0,
    scrollTop: 0,
    isDown: false,
  });
  const [movePosition, setMovePosition] = useState({
    x: 0,
    y: 0,
  });
  const [editor, setEditor] = useRecoilState(openEditor);
  const [editorPoints, setEditorPoints] = useRecoilState(openEditorPoints);

  useEffect(() => {
    getMeta(editor.image, (target: HTMLImageElement) => {
      setEditor({
        ...editor,
        naturalWidth: target.naturalWidth,
        naturalHeight: target.naturalHeight,
      });
    });
    //eslint-disable-next-line
  }, [editor.image]);

  useEffect(() => {
    const windowWheelProps = {
      editor,
      setEditor,
    };
    function scrolling(e: WheelEvent) {
      windowWheel(e, windowWheelProps);
    }
    window.addEventListener("wheel", scrolling, { passive: false });

    return () => {
      window.removeEventListener("wheel", scrolling);
    };
  }, [editor, setEditor]);

  //handleSVG
  const handleSVGProps = {
    dragging,
    grabbing,
    create,
    setCreate,
    setEditorPoints,
    editorPoints,
    setMovePosition,
  };
  const handleSVG = (e: any) => {
    handleSVGCreate(e, handleSVGProps);
  };

  const handleSVGCircle = (e: any, x: string, y: string) => {
    e.preventDefault();
    if (!dragging.visible) {
      if (!grabbing) {
        if (!create.visible) {
          setEditorPoints([
            ...editorPoints.map((item, index) => {
              return {
                ...item,
                points:
                  index === create.index
                    ? [...item.points, x + "," + y]
                    : item.points,
              };
            }),
          ]);
        }
      }
    }
  };

  //handleClose
  const handleClose = (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    e.stopPropagation();
    setEditorPoints([
      ...editorPoints.map((item, index) => {
        return {
          ...item,
          points:
            index === create.index
              ? [...item.points, item.points[0]]
              : item.points,
        };
      }),
    ]);
    setCreate({
      index: null,
      visible: true,
    });
  };

  //handleRemovePoint
  const handleRemovePoint = (
    e: React.MouseEvent<SVGCircleElement, MouseEvent>,
    index: number,
    pointIndex: number
  ) => {
    e.preventDefault();
    e.stopPropagation();
    let points = editorPoints.map((item, i) => {
      return {
        ...item,
        points:
          i === pointIndex
            ? item.points.filter((_, j) => j !== index)
            : item.points,
      };
    });
    setEditorPoints(points);
    setCreate({ index: null, visible: true });
  };

  useEffect(() => {
    const windowKeyProps = {
      grabbing,
      setGrabbing,
    };
    const windowDown = (e: KeyboardEvent) => {
      windowKeyDown(e, windowKeyProps);
    };
    window.addEventListener("keydown", windowDown, { capture: true });

    return () => {
      window.removeEventListener("keydown", windowDown, { capture: true });
    };
  }, [grabbing]);

  const grabbingProps = {
    setGrabbing,
    setPosition,
    grabbing,
    position,
    bodyRef,
  };

  const bodymouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    grabbingScrollMove(e, grabbingProps);
  };
  const bodymouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    grabbingScrollUp(e, grabbingProps);
  };
  const bodymouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    grabbingScrollDown(e, grabbingProps);
  };
  const bodymouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    grabbingScrollLeave(e, grabbingProps);
  };

  const props = {
    dragging,
    setDragging,
    editorPoints,
    setEditorPoints,
    setCreate,
    create,
    setMovePosition,
  };

  const additionalPointProps = {
    editorPoints,
    setEditorPoints,
    create,
  };

  return (
    <div
      className={styles.container}
      ref={bodyRef}
      onMouseMove={bodymouseMove}
      onMouseUp={bodymouseUp}
      onMouseDown={bodymouseDown}
      onMouseLeave={bodymouseLeave}
    >
      <svg
        id="SVGeditor"
        viewBox={`0 0 ${editor.naturalWidth} ${editor.naturalHeight}`}
        style={{ width: `${editor.size * 100}%` }}
        onClick={(e) => handleSVG(e)}
        onMouseUp={(e: any) => {
          pointsDraggingUp(e, props);
          bodymouseUp(e);
        }}
        onMouseMove={(e: any) => {
          pointsDraggingMove(e, props);
          bodymouseMove(e);
        }}
      >
        <image
          href={editor.image}
          height={editor.naturalHeight}
          width={editor.naturalWidth}
        />
        {editorPoints?.map((item, pointIndex) => (
          <>
            <polygon
              key={pointIndex}
              points={
                item.points?.join(" ") +
                ` ${
                  create.index === pointIndex
                    ? movePosition.x + "," + movePosition.y
                    : ""
                }`
              }
              fill={editor.color}
              stroke="black"
              opacity={editor.opacity / 100}
              strokeWidth={1.5}
              onClick={(e) =>
                additionalPoint(e, pointIndex, additionalPointProps)
              }
              onContextMenu={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setDeleteIndex(pointIndex);
                popoverPositon(e, setOpenPopOver);
              }}
              onDoubleClick={(e) => e.stopPropagation()}
            />
          </>
        ))}
        {editorPoints?.map((item, pointIndex) => (
          <>
            {item?.points && item?.points?.length > 0 && (
              <>
                {item?.points?.map((point, index) => (
                  <circle
                    cx={stringPosition(point).x}
                    cy={stringPosition(point).y}
                    r={2 / editor.size}
                    stroke="black"
                    stroke-width={1 / editor.size}
                    fill={"#ffffff"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSVGCircle(
                        e,
                        stringPosition(point).x,
                        stringPosition(point).y
                      );
                    }}
                    onContextMenu={(e) =>
                      handleRemovePoint(e, index, pointIndex)
                    }
                    onMouseDown={(e) => {
                      pointsDraggingDown(e, [pointIndex, index], props);
                    }}
                  />
                ))}
                <circle
                  cx={stringPosition(item?.points[0]).x}
                  cy={stringPosition(item?.points[0]).y}
                  r={4 / editor.size}
                  stroke="black"
                  stroke-width={1 / editor.size}
                  fill="#ffffff50"
                  onClick={(e) => handleClose(e)}
                  onMouseDown={(e) => {
                    pointsDraggingDown(e, [pointIndex, 0], props);
                  }}
                  onContextMenu={(e) => handleRemovePoint(e, 0, pointIndex)}
                />
                <circle
                  cx={stringPosition(item?.points[item?.points.length - 1]).x}
                  cy={stringPosition(item?.points[item?.points.length - 1]).y}
                  r={4 / editor.size}
                  stroke="black"
                  stroke-width={1 / editor.size}
                  fill="#ffffff50"
                  onClick={(e) => e.stopPropagation()}
                  onMouseDown={(e) => {
                    pointsDraggingDown(
                      e,
                      [pointIndex, item?.points.length - 1],
                      props
                    );
                  }}
                  onContextMenu={(e) =>
                    handleRemovePoint(e, item?.points.length - 1, pointIndex)
                  }
                />
                <text
                  fill="#ffffff"
                  fontSize="45"
                  x={svgCenter(item.points).x}
                  y={svgCenter(item.points).y}
                  pointerEvents="none"
                  style={{ userSelect: "none" }}
                >
                  {item.name}
                </text>
              </>
            )}
          </>
        ))}
      </svg>
      <OpenEditorPopover
        index={deleteIndex}
        {...openPopOver}
        setOpenPopOver={setOpenPopOver}
        setCreate={setCreate}
      />
    </div>
  );
};

export default OpenEditorContent;
