import { Button, Checkbox, Col, Form, Input, Row, Spin } from "antd";
import "./Login.scss";
import LoginImage from "../../Assets/Images/Login.png";
import { useSignIn } from "../../queries/mutation";
import { useNavigate } from "react-router-dom";
import { rootPaths } from "../../Routers/rootPaths";
import { LoadingOutlined } from "@ant-design/icons";

export default function Login() {
  const push = useNavigate();
  const login = useSignIn();

  const finishLogin = (fields: any) => {
    login.mutateAsync(fields).then(() => {
      push(rootPaths.DEVELOPER + rootPaths.NEWS);
    });
  };
  return (
    <div className="login">
      <Row className="login_row">
        <Col span={12}>
          <img src={LoginImage} className="login_img" alt="Login_image" />
        </Col>
        <Col span={12} className="login_col">
          <h1>
            <span>Xush kelibsiz!</span>
            Kirish
          </h1>
          <Form
            name="login"
            onFinish={finishLogin}
            className="login_col_form"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item
              name={"username"}
              rules={[{ required: true, message: "Loginni kiriting!" }]}
              label="Login"
            >
              <Input placeholder="Your login..." />
            </Form.Item>
            <Form.Item
              name={"password"}
              rules={[{ required: true, message: "Parolni kiriting!" }]}
              label="Parol"
            >
              <Input.Password placeholder="Password" className="customInput" />
            </Form.Item>
            <Form.Item>
              <Checkbox>Eslab qolish</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <Spin
                  spinning={login.isLoading}
                  style={{ color: "#ffffff", marginRight: "10px" }}
                  indicator={<LoadingOutlined />}
                />
                Kirish
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
