export const atomPaths = {
  BUILDING_BLOCK_FLOOR_MODAL: "buildingBlockFloorModal",
  BUILDING_FLOOR_FLAT_MODAL: "buildingFloorFlatModal",
  FLAT_INFO_MODAL: "flatInfoModal",
  BUILDING_BLOCK_IMPORT_IMAGE_MODAL: "buildingBlockImportImageModal",
  WEBSITE_ROLE: "websiteRole",
  POST_ID_STATE: "postIdState",
  OPEN_EDITOR: "OPEN_EDITOR",
  OPEN_EDITOR_POINTS: "OPEN_EDITOR_POINTS",
};
