import Login from "../pages/Login/Login";
import DeveloperRouter from "./developer/DeveloperRouter";
import MainRouter from "./main/MainRouter";
import { rootPaths } from "./rootPaths";

export const rootRoutes = [
  {
    path: rootPaths.INDEX,
    component: Login,
    exact: true,
  },
  {
    path: rootPaths.MAIN,
    component: MainRouter,
    exact: false,
  },
  {
    path: rootPaths.DEVELOPER,
    component: DeveloperRouter,
    exact: false,
  },
  {
    path: "*",
    component: "404",
    exact: true,
  },
];
