import CreatePost from "../../pages/News/CreatePost";
import EditPost from "../../pages/News/EditPost";
import News from "../../pages/News/News";
import { LocalStorage } from "../../Service/LocalStorage";
import { USER_KEY } from "../../utils/constants/localStorageKeys";
import { User } from "../../utils/models/User";
import { DeveloperPaths } from "../developer/developerPaths";
import { rootPaths } from "../rootPaths";
import { MainPaths } from "./mainPaths";

const user = LocalStorage.get<User>(USER_KEY);

const routes = [
  {
    path: MainPaths.INDEX,
    component: News,
    exact: true,
  },
  {
    path: rootPaths.NEWS + DeveloperPaths.CREATEPOST,
    component: CreatePost,
    exact: true,
  },
  {
    path: MainPaths.INDEX + MainPaths.EDIT,
    component: EditPost,
    exact: false,
  },
];

// const mainRoutes: typeof routes = []
const mainRoutes: typeof routes = routes;

// if (typeof user === 'object' && user) {
//   routes.forEach((route) => {
//     if (route.permission) {
//       if (user.role.permissions.includes(route.permission)) {
//         mainRoutes.push(route)
//       }
//     } else {
//       mainRoutes.push(route)
//     }
//   })
// }

export default mainRoutes;
