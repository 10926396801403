import { SetterOrUpdater } from "recoil";
import { EditorType } from "../models/editorType";
import { coordinateSVG } from "./coordinateSVG";

interface IProps {
  editorPoints: EditorType[];
  setEditorPoints: SetterOrUpdater<EditorType[]>;
  create: {
    index: number | null;
    visible: boolean;
  };
}

export const additionalPoint = (e: any, index: number, props: IProps) => {
  if (props.create.visible) {
    e.stopPropagation();
    const p = coordinateSVG(e, "SVGeditor");
    let points = props.editorPoints[index].points.map((item) =>
      item.split(",").map((item2) => Number(item2.replace('"', "")))
    );

    let p1 = points
      .map((item) => {
        return Math.sqrt((item[0] - p.x) ** 2 + (item[1] - p.y) ** 2);
      })
      .filter((prev) => prev);

    let min1 = p1.indexOf(Math.min(...p1));
    let editorPoints = JSON.parse(JSON.stringify(props.editorPoints));

    editorPoints[index].points.splice(min1, 0, `${p.x},${p.y}`);
    props.setEditorPoints(editorPoints);
  }
};
