import CategoryIcon from "../../Assets/Icons/CategoryIcon";
import GalleryIcon from "../../Assets/Icons/GalleryIcon";
import { DeveloperPaths } from "../../Routers/developer/developerPaths";
import { rootPaths } from "../../Routers/rootPaths";

export const developerlink = [
  {
    path: rootPaths.DEVELOPER + DeveloperPaths.INDEX,
    name: "Turar-joy majmualari",
    icon: <CategoryIcon color="#04C35C" />,
  },
  {
    path: rootPaths.DEVELOPER + DeveloperPaths.NEWS,
    name: "Yangiliklar",
    icon: <GalleryIcon color="#04C35C" />,
  },
];
