import React from "react";
import Posts from "./Posts";

import style from "./News.module.scss";

const News = () => {
  return (
    <div className={style.news}>
      <h1 className={style.news_title}>Yangiliklar</h1>
      <Posts />
    </div>
  );
};

export default News;
