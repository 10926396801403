interface IProps {
  grabbing: boolean;
  setGrabbing: React.Dispatch<React.SetStateAction<boolean>>;
}

export const windowKeyDown = (e: KeyboardEvent, props: IProps) => {
  if (e.code === "KeyH" || e.code === "Space") {
    if (props.grabbing) {
      document.body.style.cursor = "default";
      props.setGrabbing(false);
    } else {
      props.setGrabbing(true);
      document.body.style.cursor = "grab";
    }
  }
  if (e.code === "KeyV") {
    if (props.grabbing) {
      document.body.style.cursor = "default";
      props.setGrabbing(false);
    }
  }
  if (e.code === "Escape") {
    props.setGrabbing(false);
    document.body.style.cursor = "default";
  }
};
