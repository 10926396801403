import { Button, Select } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import CheveroRightIcon from "../../../Assets/Icons/CheveroRightIcon";
import CloseModalIcon from "../../../Assets/Icons/CloseModalIcon";
import { openEditor, openEditorPoints } from "../../../atoms";
import {
  useSendBlockFloorFlatsSVG,
  useSendBlockFloorSVG,
  useSendBlockSVG,
} from "../../../queries/mutation";
import {
  useBlockFatData,
  useBlockFloorStatistic,
  useBlockStatistic,
} from "../../../queries/queries";
import styles from "./openEditorPopover.module.scss";

interface IProps {
  x: number;
  y: number;
  visible: boolean;
  index: number;
  setOpenPopOver: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
      visible: boolean;
    }>
  >;
  setCreate: React.Dispatch<
    React.SetStateAction<{
      index: number | null;
      visible: boolean;
    }>
  >;
}

const OpenEditorPopover: FC<IProps> = ({
  visible,
  x,
  y,
  setOpenPopOver,
  setCreate,
  index,
}) => {
  const [activeBlockId, setActiveBlockId] = useState(0);
  const [svgId, setSvgId] = useState(0);
  const sendBuildingSVG = useSendBlockSVG();
  const sendBlockFloorSVG = useSendBlockFloorSVG();
  const params = useParams();
  const { data, isLoading } = useBlockStatistic(Number(params?.id));
  const [editorPoints, setEditorPoints] = useRecoilState(openEditorPoints);
  const editor = useRecoilValue(openEditor);
  const { data: blockFloorData } = useBlockFloorStatistic(
    editor.id !== null ? editor.id : null
  );
  const { data: flats } = useBlockFatData(editor?.id!, editor?.addId!);
  const flatSVG = useSendBlockFloorFlatsSVG();

  const select = {
    BLOCK: data?.visualViewBlockStatisticWithImages,
    FLOOR: blockFloorData,
    FLAT: flats,
  };

  useEffect(() => {
    setOpenPopOver((prev) => ({ ...prev, visible: false }));
  }, [editor.size, setOpenPopOver]);

  useEffect(() => {
    if (editorPoints[index] && editorPoints[index].id !== null) {
      setActiveBlockId(editorPoints[index].id!);
    }
  }, [index, editorPoints]);

  const handleClose = () => {
    setOpenPopOver((prev) => ({ ...prev, visible: false }));
  };

  const handleDelete = () => {
    let arr = [...editorPoints];
    arr.splice(index, 1);
    setEditorPoints(arr);
    setCreate((prev) => ({ visible: true, index: null }));
    handleClose();
  };

  const handleChange = (value: number) => {
    if (editor.type === "BLOCK") {
      sendBuildingSVG.mutateAsync(value).then((res) => {
        setSvgId(res[0]?.id!);
      });
    } else if (editor.type === "FLOOR") {
      sendBlockFloorSVG
        .mutateAsync({ blockId: editor?.id!, id: value })
        .then((res) => {
          setSvgId(res[0]?.id!);
        });
    } else if (editor.type === "FLAT") {
      flatSVG.mutateAsync(value).then((res) => {
        setSvgId(res.id);
      });
    }
    setActiveBlockId(value);
  };

  const handleSave = () => {
    let arr = [...editorPoints];
    let res: any = [];

    if (editor.type === "BLOCK") {
      res = arr.map((item, indexPoint) => {
        return {
          ...item,
          ...(indexPoint === index && {
            id: activeBlockId,
            name: data?.visualViewBlockStatisticWithImages.find(
              (i) => i.blockId === activeBlockId
            )?.blockName,
            backPoints: `M ${editorPoints[index].points.join(" ")} z M 0,0 ${
              editor.naturalWidth
            },0 ${editor.naturalWidth},${editor.naturalHeight} 0,${
              editor.naturalHeight
            }z`,
            svgId,
          }),
        };
      });
    } else if (editor.type === "FLOOR") {
      res = arr.map((item, indexPoint) => {
        return {
          ...item,
          ...(indexPoint === index && {
            id: activeBlockId,
            name: String(
              select?.FLOOR?.find((i) => i?.floorName === activeBlockId)
                ?.floorName
            ),
            svgId,
          }),
        };
      });
    } else if (editor.type === "FLAT") {
      res = arr.map((item, indexPoint) => {
        return {
          ...item,
          ...(indexPoint === index && {
            id: activeBlockId,
            name: String(
              select?.FLAT?.find((i) => i?.flatId === activeBlockId)?.flatNumber
            ),
            svgId,
          }),
        };
      });
    }
    setEditorPoints(res);
    handleClose();
  };

  return (
    <div
      className={`${styles.container} ${
        !visible ? styles.container_passive : ""
      }`}
      style={{ top: y, left: x }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.container_title}>
        Xonadonni tanlang
        <span onClick={handleClose}>
          <CloseModalIcon />
        </span>
      </div>
      <div className={styles.container_content}>
        <Select
          placeholder="Tanlash"
          suffixIcon={<CheveroRightIcon />}
          loading={isLoading}
          onChange={(value) => handleChange(value)}
          value={activeBlockId}
          allowClear
        >
          {editor.type === "BLOCK" ? (
            select.BLOCK?.map((item) => (
              <Select.Option value={item.blockId} key={item.blockId}>
                {item.blockName}
              </Select.Option>
            ))
          ) : editor.type === "FLOOR" ? (
            select.FLOOR?.map((item) => (
              <Select.Option value={item.floorName} key={item.floorName}>
                {item.floorName}-qavat
              </Select.Option>
            ))
          ) : editor.type === "FLAT" ? (
            select.FLAT?.map((item) => (
              <Select.Option value={item.flatId} key={item.flatId}>
                {item.flatNumber}-xonadon
              </Select.Option>
            ))
          ) : (
            <></>
          )}
        </Select>
      </div>
      <div className={styles.container_footer}>
        <Button onClick={handleDelete}>O'chirish</Button>
        <Button onClick={handleSave}>Saqlash</Button>
      </div>
    </div>
  );
};

export default OpenEditorPopover;
