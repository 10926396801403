import React from "react";

export default function SearchIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.707 19.293L17.025 15.611C18.4651 13.8095 19.1607 11.5249 18.9688 9.22654C18.777 6.92817 17.7123 4.79051 15.9935 3.25267C14.2746 1.71483 12.0321 0.893565 9.72666 0.957581C7.42118 1.0216 5.22773 1.96603 3.59688 3.59688C1.96603 5.22773 1.0216 7.42118 0.957581 9.72666C0.893565 12.0321 1.71483 14.2746 3.25267 15.9935C4.79051 17.7123 6.92817 18.777 9.22654 18.9688C11.5249 19.1607 13.8095 18.4651 15.611 17.025L19.293 20.707L20.707 19.293ZM10 17C8.61557 17 7.26219 16.5895 6.11104 15.8203C4.9599 15.0512 4.06269 13.9579 3.53288 12.6788C3.00307 11.3997 2.86444 9.99227 3.13454 8.6344C3.40464 7.27654 4.07132 6.02926 5.05029 5.05029C6.02926 4.07132 7.27654 3.40464 8.6344 3.13454C9.99227 2.86444 11.3997 3.00307 12.6788 3.53288C13.9579 4.06269 15.0512 4.9599 15.8203 6.11104C16.5895 7.26219 17 8.61557 17 10C16.9979 11.8559 16.2597 13.6351 14.9474 14.9474C13.6351 16.2597 11.8559 16.9979 10 17Z"
        fill="black"
      />
    </svg>
  );
}
