import { SetterOrUpdater } from "recoil";
import { EditorType } from "../models/editorType";
import { coordinateSVG } from "./coordinateSVG";

interface pointsDraggingDownType {
  dragging: { index: number[] | null; visible: boolean };
  setDragging: React.Dispatch<
    React.SetStateAction<{
      index: number[] | null;
      visible: boolean;
    }>
  >;
  editorPoints: EditorType[];
  setEditorPoints: SetterOrUpdater<EditorType[]>;
  setCreate: React.Dispatch<
    React.SetStateAction<{
      index: number | null;
      visible: boolean;
    }>
  >;
  create: {
    index: number | null;
    visible: boolean;
  };
  setMovePosition: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    }>
  >;
}

export const pointsDraggingDown = (
  e: React.MouseEvent<SVGCircleElement, MouseEvent>,
  index: number[],
  props: pointsDraggingDownType
) => {
  e.stopPropagation();
  props.setDragging({ index, visible: true });
};

export const pointsDraggingUp = (
  e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  props: pointsDraggingDownType
) => {
  //   e.preventDefault();
  e.stopPropagation();
  props.setDragging({ index: null, visible: false });
};

export const pointsDraggingMove = (
  e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  props: pointsDraggingDownType
) => {
  //   e.preventDefault();
  e.stopPropagation();
  if (props.dragging.visible || !props.create.visible) {
    const p = coordinateSVG(e, "SVGeditor");
    props.setMovePosition({ x: p.x, y: p.y });
    if (props.dragging.index) {
      let arr = props.editorPoints.map((item, i) => {
        return i === props.dragging.index![0]
          ? {
              ...item,
              points: item.points.map((item2, j) =>
                j === props.dragging.index![1] ? p.x + "," + p.y : item2
              ),
            }
          : {
              ...item,
              points: item.points,
            };
      });
      props.setEditorPoints(arr);
    }
  }
};
