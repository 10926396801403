import { Button, Slider } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { openEditor, openEditorPoints } from "../../../atoms";
import {
  useBuildingFloorSvgImageCreate,
  useBuildingSvgImageCreate,
  useFlatSVGCreate,
} from "../../../queries/mutation";
import { colors } from "../../../utils/constants/colors";
import styles from "./openEditorHeader.module.scss";

const OpenEditorHeader = () => {
  const [editor, setEditor] = useRecoilState(openEditor);
  const editorPoints = useRecoilValue(openEditorPoints);
  const buildingSVG = useBuildingSvgImageCreate();
  const buildingFloorSVGCreate = useBuildingFloorSvgImageCreate();
  const flatSVGCreate = useFlatSVGCreate(editor.id!, editor.addId!);

  const changeSize = (e: number) => {
    setEditor({
      ...editor,
      size: e,
    });
  };

  const changeOpacity = (e: number) => {
    setEditor({
      ...editor,
      opacity: e,
    });
  };

  const handleColor = (e: string) => {
    setEditor({
      ...editor,
      color: e,
    });
  };

  const handleSave = () => {
    if (editor.type === "BLOCK") {
      editorPoints
        .filter((item) => item.id)
        .forEach((item) => {
          buildingSVG.mutate({
            buildingId: item.id!,
            frontImage: item.points.join(" "),
            backgroundImage: item.backPoints!,
            ...(item.svgId && { id: item.svgId }),
          });
        });
    } else if (editor.type === "FLOOR") {
      editorPoints
        .filter((item) => item.id !== null && item.id !== undefined)
        .forEach((item) => {
          buildingFloorSVGCreate.mutate({
            floor: item?.id!,
            floorPoint: item.points.join(" "),
            ...(item.svgId && { id: item.svgId }),
            buildingId: editor.id!,
          });
        });
    } else if (editor.type === "FLAT") {
      editorPoints
        .filter((item) => item.id && item?.points)
        .forEach((item) => {
          flatSVGCreate.mutate({
            flatId: item.id!,
            imagePoint: item.points.join(" "),
            id: item?.svgId || null,
          });
        });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_item}>
        <p>Qavatni tanlashdagi vizual rangni belgilang</p>
        <div className={styles.container_item_colors}>
          {colors.map((item, index) => (
            <span
              key={index}
              onClick={() => handleColor(item.color)}
              style={{ background: `${item.color}` }}
              className={
                editor.color === item.color
                  ? styles.container_item_colors_active
                  : ""
              }
            ></span>
          ))}
        </div>
      </div>
      <div className={styles.container_item}>
        <p>Masshtab</p>
        <div className={styles.container_item_slider}>
          <span>+</span>
          <Slider
            value={editor.size}
            max={4}
            step={0.01}
            onChange={(e) => changeSize(e)}
          />
          <span>-</span>
        </div>
      </div>
      <div className={styles.container_item}>
        <p>Shaffoflikni belgilang</p>
        <div className={styles.container_item_slider}>
          <Slider
            max={100}
            value={editor.opacity}
            onChange={(e) => changeOpacity(e)}
          />
        </div>
      </div>
      <Button
        type="primary"
        className={styles.container_submit}
        onClick={handleSave}
      >
        Saqlash
      </Button>
    </div>
  );
};

export default OpenEditorHeader;
